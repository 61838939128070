import axios from "@/plugins/Axios";

const model = "marcos-legales-generales-versions";

function remove(id) {
  return axios.delete(`${process.env.VUE_APP_API_URL}/${model}/${id}`);
}

function update(id, data) {
  return axios.put(`${process.env.VUE_APP_API_URL}/${model}/${id}`, data);
}

function find(id) {
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}/${id}`);
}

function marcosLegalesEspecificos(id) {
  return axios.get(
    `${process.env.VUE_APP_API_URL}/${model}/${id}/marcos-legales-especificos`
  );
}

function createMarcosLegalesEspecificos(id, data) {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/${model}/${id}/marcos-legales-especificos`,
    data
  );
}

export {
  remove,
  update,
  find,
  marcosLegalesEspecificos,
  createMarcosLegalesEspecificos,
};
