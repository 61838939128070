<template>
  <div>
    <router-link to="/admin/marcos-legales-generales" v-if="marcoLegalGeneralId"
      >MLG</router-link
    >
    /
    <router-link
      :to="`/admin/marcos-legales-generales/${this.marcoLegalGeneralId}/versiones`"
      v-if="versionesAvailableFor.includes(currentRoute)"
      >Versiones
    </router-link>
    <span v-if="marcosLegalesEspecificosAvailablesFor.includes(currentRoute)">
      /
    </span>
    <router-link
      :to="`/admin/marcos-legales-generales/${this.marcoLegalGeneralId}/versiones/${this.marcoLegalGeneralVersionId}/marcos-legales-especificos`"
      v-if="marcosLegalesEspecificosAvailablesFor.includes(currentRoute)"
      >Marco legal específico
    </router-link>
    <span v-if="toolsAvailablesFor.includes(currentRoute)"> / </span>
    <router-link
      :to="`/admin/marcos-legales-generales/${this.marcoLegalGeneralId}/versiones/${this.marcoLegalGeneralVersionId}/marcos-legales-especificos/${this.marcoLegalEspecificoId}/herramientas`"
      v-if="toolsAvailablesFor.includes(currentRoute)"
      >Herramientas</router-link
    >
  </div>
</template>
<script>
export default {
  data() {
    return {
      marcoLegalGeneralId: this.$route.params.id,
      marcoLegalGeneralVersionId: this.$route.params.versionId,
      marcoLegalEspecificoId: this.$route.params.mleId,
      currentRoute: this.$route.name,
      versionesAvailableFor: [
        "MarcosLegalesGeneralesVersionesList",
        "MarcosLegalesEspecificosList",
        "MarcosLegalesEspecificosHerramientasList",
      ],
      marcosLegalesEspecificosAvailablesFor: [
        "MarcosLegalesEspecificosList",
        "MarcosLegalesEspecificosHerramientasList",
      ],
      toolsAvailablesFor: ["MarcosLegalesEspecificosHerramientasList"],
    };
  },
};
</script>
<style lang="sass" scoped>
.router-link-exact-active
  color: #333333
  cursor: default
</style>
