<template>
  <div class="container">
    <m-l-g-menu />
    <div class="text-end p-3">
      <c-primary-button
        :to="`/admin/marcos-legales-generales/${marcoLegalGeneralId}/versiones/crear`"
      >
        Crear nueva versión
      </c-primary-button>
    </div>
    <c-card :title="`Marco legal general: ${marcoLegalGeneral.name}`">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Versión</th>
            <th>Creación</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="version in versions" :key="version.id">
            <td>{{ version.version }}</td>
            <td>{{ version.created_at }}</td>
            <td>
              <div class="dropdown">
                <a
                  class="btn btn-sm btn-secondary dropdown-toggle"
                  href="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Opciones
                </a>

                <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <li>
                    <router-link
                      class="dropdown-item"
                      :to="`/admin/marcos-legales-generales/${marcoLegalGeneralId}/versiones/${version.id}/marcos-legales-especificos`"
                      >Marcos legales específicos</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      class="dropdown-item"
                      :to="`/admin/marcos-legales-generales/${marcoLegalGeneralId}/versiones/${version.id}/editar`"
                      >Editar</router-link
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      href="#"
                      @click="deleteVersion(version.id)"
                      >Eliminar</a
                    >
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </c-card>
  </div>
</template>
<script>
import CCard from "@/components/CCard.vue";
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import { remove } from "@/services/marcos_legales_generales_versions";
import { showNotification } from "@/services/notification";
import { find, versions } from "@/services/marcos_legales_generales";
import MLGMenu from "@/components/MarcoLegalGeneral/Menu.vue";
export default {
  components: {
    CCard,
    CPrimaryButton,
    MLGMenu,
  },
  data() {
    return {
      marcoLegalGeneralId: this.$route.params.id,
      marcoLegalGeneral: { name: "" },
      versions: [],
    };
  },
  mounted() {
    this.getMarcoLegalGeneral();
    this.getVersions();
  },
  methods: {
    getMarcoLegalGeneral() {
      find(this.marcoLegalGeneralId).then((response) => {
        this.marcoLegalGeneral = response.data;
      });
    },
    getVersions() {
      versions(this.marcoLegalGeneralId).then((response) => {
        this.versions = response.data;
      });
    },
    deleteVersion(id) {
      const removeRecord = confirm("¿Estás seguro de eliminar el registro?");
      if (!removeRecord) {
        return;
      }
      remove(id).then(() => {
        showNotification(204, "success", "El registro fue eliminado.");
        this.getVersions();
      });
    },
  },
};
</script>
